<template>
  <member-container>
    <b-card class="pl-2 pr-2 pt-1">
    <div class="d-flex flex-wrap justify-content-between my-2">
      <h4>
        {{ $t('messages.title') }}
        <b-badge pill variant="primary" class="align-self-start ml-50 pb-25">
          Beta
        </b-badge>
      </h4>
      
      <!-- <b-button
        variant="primary"
        disabled  
      >
        Compose
      </b-button> -->
    </div>
    <b-card class="tabla">
      <b-row>
        <b-col cols="2">
          <div class="menu">
            <b-nav vertical class="w-60">
              <b-nav-item v-b-tooltip.hover :title="$t('messages.inbox-tooltip')" v-bind:class="{ active: isActiveRecibidos }">
                <div @click="emailRecibidos(null)">
                  <feather-icon
                      icon="MailIcon"
                      size="18"
                      class="mr-75 float-left icon"
                    />
                    <span class="menutext html-text-ellipsis-1">{{ $t('messages.inbox') }}</span>
                </div>
              </b-nav-item>
              <b-nav-item v-b-tooltip.hover :title="$t('messages.sent-tooltip')" class="mt-1" v-bind:class="{ active: isActiveSend }" >
                <div @click="emailSents(null)">
                  <feather-icon
                    icon="SendIcon"
                    size="18"
                    class="mr-75 float-left"
                  />
                    <span class="menutext html-text-ellipsis-1">{{ $t('messages.sent') }}</span>
                </div>
              </b-nav-item>
              <!-- <b-nav-item v-b-tooltip.hover :title="$t('messages.drafts-tooltip')" class="mt-1" v-bind:class="{ active: isActiveDraft }" >
                <div @click="emailDrafts(null)">
                  <feather-icon
                    icon="Edit2Icon"
                    size="18"
                    class="mr-75 float-left"
                  />
                    <span class="menutext html-text-ellipsis-1">{{ $t('messages.drafts') }}</span>
                </div>
              </b-nav-item>
              <b-nav-item v-b-tooltip.hover :title="$t('messages.nectios-tooltip')" class="mt-1" v-bind:class="{ active: isActiveNectios }" >
                <div @click="emailNectios(null)">
                  <feather-icon
                    icon="ServerIcon"
                    size="18"
                    class="mr-75 float-left"
                  />
                    <span class="menutext html-text-ellipsis-1">Nectios</span>
                </div>
              </b-nav-item> -->
            </b-nav>
          </div>
        </b-col>
        <b-col cols="10" style="padding: 0px !important">
          <div v-if="isLoading">
            <b-spinner class="d-block mx-auto mt-3" />
          </div>
          <div v-else-if="(isDetails)">
            <email-details @return="selectToWhichSend" :email="email"></email-details>
          </div>
          <div v-else-if="emails != null && emails.length > 0 && draft === false && !nectiosEmail && !inbox ">
            <div v-for="(email,index) in emails" :key="index" class="email" v-bind:class="{'borde': (index != emails.length-1 || index < 2)}" @click="emailDetails(email, dataType)">
              <div class="pt-2 conta">
                <b-row>
                  <b-col class="foto" cols="3" md="2" sm="3"  xl="1">
                    <div class="ml-2">
                      <b-avatar
                        v-if="send"
                        size="38"
                        :src="email.toUser.avatarURL || null"
                        class="user-avatar float-right"
                      />
                      <b-avatar
                        v-if="inbox"
                        size="38"
                        :src="email.fromUser.avatarURL || null"
                        class="user-avatar float-right"
                      />
                    </div>
                  </b-col>
                  <b-col class="nombres" xl="8" lg="7" md="7" sm="6" cols="5">
                    <h5 class="name html-text-ellipsis-1 ">{{email.toUser != null && typeof email.toUser === "object" ? `${email.toUser.name} ${email.toUser.surname}` : email.toUser}}</h5>
                    <p class="html-text-ellipsis-1 subject">{{email.toUser != null && typeof email.toUser === "object" && email.toUser.headline != null ? `${email.toUser.headline}` :  $t('messages.member')}}</p>
                  </b-col>
                  <b-col class="createdAt" xl="3" lg="3" md="3" cols="3">
                    <p class="text-right html-text-ellipsis-1 createdAt mr-2">{{email.createdAt}}
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <div class="pl-3 html-text-ellipsis-1 pr-1">
                      <p class="html-text-ellipsis-1">{{email.subject}} - <span class="text-email">{{email.body}}</span></p>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
          <div v-else-if="inboxEmails != null && inboxEmails.length > 0 && draft === false && !nectiosEmail ">
            <div v-for="(email,index) in inboxEmails" :key="index" class="email" v-bind:class="{'borde': (index != inboxEmails.length-1 || index < 2)}" @click="emailDetails(email, dataType)">
              <div class="pt-2 conta">
                <b-row>
                  <b-col class="foto" cols="3" md="2" sm="3"  xl="1">
                    <div class="ml-2">
                      <b-avatar
                        v-if="inbox"
                        size="38"
                        :src="email.fromUser.avatarURL || null"
                        class="user-avatar float-right"
                      />
                    </div>
                  </b-col>
                  <b-col class="nombres" xl="8" lg="7" md="7" sm="6" cols="5">
                    <h5 v-if="inbox" class="name html-text-ellipsis-1 ">{{email.fromUser.name}} {{email.fromUser.surname}}</h5>
                    <p class="html-text-ellipsis-1 subject"> {{email.fromUser != null && typeof email.fromUser === "object" && email.fromUser.headline != null ? `${email.fromUser.headline}` : $t('messages.member')}}</p>
                  </b-col>
                  <b-col class="createdAt" xl="3" lg="3" md="3" cols="3">
                    <p class="text-right html-text-ellipsis-1 createdAt mr-2">{{email.createdAt}}
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <div class="pl-3 html-text-ellipsis-1 pr-1">
                      <p class="html-text-ellipsis-1">{{email.subject}} - <span class="text-email">{{email.body}}</span></p>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
          <div v-else-if="nectiosEmail != null && nectiosEmail.length > 0 && draft === false">
            <div v-for="(email,index) in nectiosEmail" :key="index" class="email" v-bind:class="{'borde': (index != nectiosEmail.length-1 || index < 2)}" @click="emailDetails(email, dataType)">
              <div class="pt-2 conta">
                <b-row>
                  <b-col class="foto" cols="3" md="2" sm="3"  xl="1">
                    <div class="ml-2">
                      <b-avatar
                        size="38"
                        :src="LogoNectios"
                        class="user-avatar logoNectios float-right"
                      />
                    </div>
                  </b-col>
                  <b-col class="nombres" xl="8" lg="7" md="7" sm="6" cols="5">
                    <h5 class="name html-text-ellipsis-1 ">{{email.fromUser}}</h5>
                    <p class="html-text-ellipsis-1 subject">{{ $t('messages.nectios-platform') }}</p>
                  </b-col>
                  <b-col class="createdAt" xl="3" lg="3" md="3" cols="3">
                    <p class="text-right html-text-ellipsis-1 createdAt mr-2">{{email.createdAt}}
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <div class="pl-3 html-text-ellipsis-1 pr-1">
                      <p class="html-text-ellipsis-1">{{email.subject}} - <span class="text-email">{{email.body}}</span></p>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
          <div v-else>
            <b-row class="horizontal-placeholder">
              <b-col cols="12">
                <img :src="EmailsPlaceholder">
              </b-col>
              <b-col cols="12" style="padding: 0px !important">
                <p class="text-primary">
                  {{ $t('available.message', { itemName: 'emails' }) }}
                </p>     
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card> 
    </b-card>
  </member-container>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BFormTextarea, BMedia, BFormFile, BMediaAside, BMediaBody, BImg, BButton, BRow, BCol, BFormInvalidFeedback, BAlert, BNav, BNavItem, BAvatar, BCard, BTooltip
} from 'bootstrap-vue';
import { getImageResource } from '@/@core/utils/image-utils';
import vSelect from 'vue-select';
import LogoPlaceholder from '@/assets/images/placeholders/light/icono-general-light.png';
import BannerPlaceholder from '@/assets/images/placeholders/light/header.jpeg';
import LogoNectios from '@/assets/images/avatars/Nectios_Logo.png';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import { required, max } from '@validations';
import ActiveLinksLabels from '@/@core/constants/ActiveLinksLabels';
import EmailsPlaceholder from '@/assets/images/placeholders/light/email.svg';
import MemberContainer from '@/views/myself/components/MemberSettingContainer.vue';
import EmailDetails from '@/views/myself/components/EmailDetails.vue';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import { BSpinner } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BForm,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMediaAside,
    BMediaBody,
    BMedia,
    BFormFile,
    BImg,
    BButton,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormInvalidFeedback,
    BAlert,
    BNav,
    BNavItem,
    MemberContainer,
    SafeImg,
    BAvatar,
    BSpinner,
    EmailDetails,
    BTooltip,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      form: {
        password: '',
        newPassword: '',
        newPassword2: ''
      },
      required,
      max,
      isStaff: false,
      date:"",
      recibidos: false,
      isLoading: true,
      isActiveSend: true,
      isActiveRecibidos: false,
      isDetails: false,
      email: null,
      send: false,
      inbox: false,
      nectios: false,
      draft: false,
      nectiosEmail: null,
      inboxEmails: null,
    };
  },
  setup() {
    const {
      getValidationState,
    } = formValidation(() => {});
    return {
      getValidationState,
    };
  },
  computed: {
    dataType() {
      return this.$route.query.type;
    },
    LogoNectios(){
      return LogoNectios;
    },
    emails(){
      return this.$store.getters.emails.unpaginated;
    },
    EmailsPlaceholder() {
      return EmailsPlaceholder;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    currentMember() {
      return this.$store.getters.loggedMember;
    },
    username() {
      return this.$route.params.username;
    },
    isSaveEnabled() {
      return this.form.name && this.form.slug && (this.form.accessibilityAux || this.form.accessibility) && (this.form.visibilityAux || this.form.visibility);
    },
    isSubcommunity() {
      return this.currentCollective.parentKey != null;
    },
    isDisabled() {
      return !this.form.name || !this.form.slug || !this.form.visibility || !this.form.accessibility;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  async created() {
   if(this.$route.query.key != null && this.$route.query.type != null){
     if(this.$route.query.type === "inbox"){
      await this.emailRecibidos(this.$route.query.key)
     }else if(this.$route.query.type === "nectios"){
       await this.emailNectios(this.$route.query.key);
     }else{
      await this.emailSents(this.$route.query.key);
     }
   }else{
     if(this.$route.query.type === "sent"){
      this.send = true;
      await this.emailSents();
     }else if(this.$route.query.type === "nectios"){
       this.nectios = true;
       await this.emailNectios();
     }else{
       this.inbox = true;
       await this.emailRecibidos();
     }
   }
  },
  methods: {
    emailDetails(mail, type)
    {
      this.$router.replace({name: "email", query: {key: mail.key, type}})
      this.email = mail
      this.isDetails = true;
    },
    async selectToWhichSend(){
      if(this.$route.query.type === "sent"){
      this.send = true;
      await this.emailSents();
     }else if(this.$route.query.type === "inbox"){
       this.inbox = true;
       await this.emailRecibidos();
     }else{
       this.nectios = true;
       await this.emailNectios();
     }
    },
    async emailSents(key = null) {  
        this.$router.replace({name: "email", query: {type: "sent"}})
        this.isLoading = true
        this.isActiveRecibidos = false
        this.isActiveSend = true;
        this.isActiveDraft= false;
        this.isActiveNectios = false;
        this.isDetails = false;
        this.recibidos = false;
        await this.$store.dispatch('emailSents', {key});
        if(key != null){
          this.email = this.$store.getters.emails.unpaginated[0]
          this.isDetails = true;
        }
        this.nectiosEmail = null;
        this.send = true;
        this.inbox = false;
        this.draft = false;
        this.nectios = false;
        this.isLoading = false
        return ''
      
    },
    async emailRecibidos(key = null){
     this.$router.replace({name: "email", query: {type: "inbox"}})
     this.$route.query.type = "inbox";
     this.isActiveRecibidos = true;
     this.isActiveSend = false
     this.isActiveDraft= false;
     this.isActiveNectios = false;
     this.isDetails = false;
     this.isLoading = true;
     await this.$store.dispatch('inbox', {key});
      if(key != null){
        this.email = this.$store.getters.emails.unpaginated[0]
        this.isDetails = true;
      }
      this.inboxEmails = this.emails.filter(email => email.fromUser != 'Nectios')
      this.nectiosEmail = null;
      this.send = false;
      this.inbox = true;
      this.draft = false;
      this.nectios = false;
      this.isLoading = false;
    },
    async emailDrafts(key = null){
     this.$router.replace({name: "email", query: {type: "drafts"}})
     this.$route.query.type = "drafts";
     this.isActiveRecibidos = false;
     this.isActiveSend = false;
     this.isActiveDraft= true;
     this.isActiveNectios = false;
     this.isDetails = false;
     this.isLoading = true;
    //  await this.$store.dispatch('inbox', {key});
    //   if(key != null){
    //     this.email = this.$store.getters.emails.unpaginated[0]
    //     this.isDetails = true;
    //   }
      this.nectiosEmail = null;
      this.send = false;
      this.inbox = false;
      this.draft = true;
      this.nectios = false;
      this.isLoading = false;
    },
    async emailNectios(key = null){
     this.$router.replace({name: "email", query: {type: "nectios"}})
     this.$route.query.type = "nectios";
     this.isActiveRecibidos = false;
     this.isActiveSend = false;
     this.isActiveDraft = false;
     this.isActiveNectios = true;
     this.isDetails = false;
     this.isLoading = true;
     await this.$store.dispatch('inbox', {key});
      if(key != null){
        this.email = this.$store.getters.emails.unpaginated[0]
        this.isDetails = true;
      }
      this.nectiosEmail = this.emails.filter(email => email.fromUser === 'Nectios');
      this.send = false;
      this.inbox = false;
      this.draft = false;
      this.nectios = true;
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes
.logoNectios{
  border-radius:0px!important;
}
.row{
  width: 100%;
}
.card-body{
  padding: 0px!important;
}
.menu{
  // border-top: 1px #d4d2d2 solid;
  border-right: 1px #d4d2d2 solid;
  min-height: 300px;
  height: 100%;
}
.foto{
  max-width: 90px;
}
.nav{
  padding-top:20px;
}
.createdAt{
    padding-right: 0px!important;
  }
.nav-item {
  font-size:1.1em;
  color: black!important ;
}
.active{
  font-weight: bold;
  border-left: 2px hsl(186deg 64% 44%)solid ;
}
.nav-item :hover{
  font-weight: bold;
}
.col{
  padding-left: 0px;
  padding-right: 0px;
}
.col-2{
  padding-right: 0px;
}
.email{
  padding-left:0px !important;
  height: 120px;
  cursor: pointer;
  transition: box-shadow 0.5s;
}
.borde{
  border-bottom: 1px solid #d4d2d2;
}
.email:hover{
  border-left: 2px hsl(186deg 64% 44%)solid ;
}
.row{
  margin-left: 0 !important
}
.text-email{
  color: #c4c3c2;
}
.img{
  height: 5px;
  width: 10px;
}
.col-9{
  padding-left: 0px!important
}

@media (max-width: 1300px) {
 .createdAt{
    margin-right: 10px;
  }
}

@media (max-width: 1040px) {
 .createdAt{
    margin-right: 0px;
  }
}

@media (max-width: 592px) {
 .name{
    font-size: 0.9em;
  }
  .subject{
    font-size: 0.9em;
  }
  .createdAt{
    font-size: 0.9em;
    padding: 0px!important;
    margin: 0px;
  }
  .nombres{
    padding: 0px;
  }

  .menutext{
    opacity: 0;
    font-size: 0.7em;

  }

}

@media (max-width: 592px) {
  .user-avatar{
    height: 30px!important;
    width: 30px!important;
    margin:0px!important;
    padding:0px!important;
    float:none!important;
  }
  .foto{
    
    padding:0px 10px 0px 0px!important;
    margin:0px!important;
  }
  .text-email{
    padding-left: 0px!important;
  }
}


.settings-form {
  @include media-breakpoint-up(md) {
  width: 75%;
  }
  @include media-breakpoint-up(lg) {
  width: 50%;
  }
  .avatar {
    width: 80px!important;
    height: 80px!important;
  }
}
.field.has-value:not(.no-label) .field-input[data-v-5b500588] { // Class of VueCtkDateTimePicker
  color: $dark;
}
</style>
